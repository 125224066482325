import React, { useCallback, useEffect } from 'react'
import { seat } from '../../api/dytrxApi'
import { utils } from '../../utils/utils'
import { useLocation } from 'react-router-dom'

export const ScheduleListTable = ({list, formData, setFormData, handleInputChange}) => {

  const {pathname} = useLocation();

  const isTimeBefore = (row) => {
    return utils.isTimeBefore(row, pathname);
  }


  const isSelected = (row) => {
    if (isTimeBefore(row)) {
      return row?.StartTime === formData?.movie?.StartTime
    }

    return false
  }

  const handleMovieTableRadioChange = useCallback((row) => {
    if (row.StartTime !== formData?.movie?.StartTime) {
      if (isTimeBefore(row)) {
        handleInputChange(
          {
            target:
              [
                {name: 'movie', value: JSON.stringify(row)},
                {name: 'screen', value: row?.ScreenNm}
              ]
          }
        ) // 핸들러 호출

        if (row?.StartTime !== formData?.movie?.StartTime) {
          seat.movieDetail(row?.MovieCd).then((r) => {
            setFormData((prev) => ({
              ...prev,
              imgUrl: r?.Recordset?.[0]?.Url || ''
            }))
          })
        }
      }
    }
  }, [formData?.movie])

  const cell = [
    {title: '영화명', value: 'MovieNm'},
    {title: '상영일자', value: 'PlaySDT'},
    {title: '상영관명', value: 'ScreenNm'},
    {title: '시작시간', value: 'StartTime'},
    {title: '상영타입', value: 'MovieKindNm'},
    {title: '총 좌석수', value: 'TotalSeatCnt'},
    {title: '남은 좌석수', value: 'RemainSeatCnt'},
    {title: '보류 좌석수', value: 'HoldSeatCnt'},
  ]

  const getClass = (row) => {
    const backgroundClass = isSelected(row)
      ? 'bg-gray-100'
      : isTimeBefore(row)
        ? 'cursor-pointer hover:bg-gray-50 transition'
        : 'bg-gray-200 cursor-default';

    const cursorClass = isTimeBefore(row) ? 'cursor-pointer' : 'cursor-default';

    return `${backgroundClass} ${cursorClass}`
  }

  useEffect(() => {
    const checkedRadioInput = document.querySelector('input[name="movie"]:checked')

    if (!formData?.movie?.MovieNm && checkedRadioInput) {
      checkedRadioInput.checked = false // 체크된 라디오 버튼의 값
    }
  }, [formData?.movie])


  return (
    <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
      <thead className="ltr:text-left rtl:text-right text-center bg-gray-50">
      <tr className={'text-xs'}>
        <th className="sticky inset-y-0 start-0 bg-gray-50 px-2.5 py-2">
        </th>

        <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
          회차
        </th>

        {cell.map((r, id) => (
          <th key={r.title} className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">{r.title}</th>
        ))}
      </tr>
      </thead>

      <tbody className="divide-y divide-gray-200">
      {list?.map((row, idx) => (
        <tr className={`text-center ${getClass(row)}`}
            key={`${row.PlaySDT} ${row.StartTime}`}
            onClick={() => handleMovieTableRadioChange(row)}
        >
          <td className={`sticky inset-y-0 start-0 px-2.5 py-2 bg-inherit`}>
              <label className="sr-only" htmlFor="list">list</label>

              <input
                className={`text-black ${getClass(row)} border-gray-300 focus:ring-black focus:ring-1 size-4`}
                type="radio"
                id="list"
                required
                name="movie"
                value={JSON.stringify(row)}
                onChange={handleInputChange}
                disabled={!isTimeBefore(row)}
                checked={isSelected(row)}
              />
          </td>
          <td className={`text-xs whitespace-nowrap px-4 py-2 ${isTimeBefore(row) ? 'text-gray-800' : 'text-gray-400'}`}>
            {idx +1}
          </td>
          {cell.map((r, id) => (
            <td key={r.title} className={`text-xs whitespace-nowrap px-4 py-2 ${isTimeBefore(row) ? 'text-gray-800' : 'text-gray-400'}`}
            >
              {row[r.value]}
            </td>
          ))}
        </tr>
      ))}
      </tbody>
    </table>
  )
}