import ajax from './ajax'

const couponCheckApiUrl = process.env.REACT_APP_COUPON_CHECK_API_URL
const couponAddApiUrl = process.env.REACT_APP_COUPON_ADD_API_URL
const couponBearerToken = process.env.REACT_APP_COUPON_BEARER_TOKEN

export const table = {
  couponCheck(couponCode) {
    return ajax.get(
      couponCheckApiUrl,
      {
        filterByFormula: `TRIM({Code})='${couponCode}'` // params로 전달
      },
      null,
      {
        Authorization: `Bearer ${couponBearerToken}` // headers로 전달
      }
    )
  },
  usedCouponCheck(couponCode, recordId) {
    return ajax.patch(
      `${couponCheckApiUrl}/${recordId}`,
      {
        fields: {
          Used: true
        } // Used 필드를 true로 설정
      },
      null,
      {
        Authorization: `Bearer ${couponBearerToken}`, // Authorization 헤더
        'Content-Type': 'application/json'
      }
    );
  },
  couponDuplicateCheck(couponCode) {
    return ajax.get(
      couponAddApiUrl,
      {
        filterByFormula: `TRIM({Code})='${couponCode}'` // params로 전달
      },
      null,
      {
        Authorization: `Bearer ${couponBearerToken}` // headers로 전달
      }
    )
  },
  usedCouponSeatCheck(date) {
    return ajax.get(
      couponAddApiUrl,
      {
        filterByFormula: `{Date}='${date}'` // params로 전달
      },
      null,
      {Authorization: `Bearer ${couponBearerToken}`}
    )
  },
  postCoupon(payload) {
    return ajax.post(
      couponAddApiUrl,
      {
        fields: payload
      },
      null,
      null,
      {
        Authorization: `Bearer ${couponBearerToken}`
      }
    )
  }
}