import React, { useEffect, useState } from 'react'
import { seat } from '../../api/dytrxApi'
import { registerLocale } from 'react-datepicker'
import ko from 'date-fns/locale/ko'
import { format } from 'date-fns'
import { ScheduleTable } from '../../components/schedule/ScheduleTable'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { utils } from '../../utils/utils'
import { SalesPackageSeat } from './components/SalesPackageSeat'
import { OrderState } from './components/OrderState'
import { Helmet } from 'react-helmet-async'
import { table } from '../../api/airTableAPi'

export const Sales = () => {
  registerLocale('ko', ko) //날짜 한국어로 표시

  const [seatMatrix, setSeatMatrix] = useState([])
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [seatFocus, setSeatFocus] = useState(null)
  const [formData, setFormData] = useState({
    gender: '',
    name: '',
    phoneNumber: '',
    couponCode: [{id: null, code: null, status: null, basicPackage: null}],
    date: new Date(),
    screen: '',
    imgUrl: '',
    movie: {}
  })
  const [soldSeat, setSoldSeat] = useState([])

  // 스케줄
  const getDtryxHistoryListApi = () => {
    setFormData(prev => ({
      ...prev, movie: {}
    }))

    const {date} = formData
    if (date) {
      const formatDate = format(new Date(date), 'yyyy-MM-dd')
      seat.scheduleList(formatDate).then(list => {
        const filter = list.Recordset.filter(r => r.PlanStatus === 'confirm') // 확정된 스케줄만 노출
        setList(filter)
      })
    }
  }

  // 패키지 판매 좌석
  const getDtryxSalesSeatListApi = async () => {
    const {movie: {PlaySDT, ScreenCd, ShowSeq}} = formData

    // 판매완료인 것들만 filter
    const soldSeat = seatMatrix.flat().filter(seat => seat?.SeatStatus === 'soldout')

    const createPayload = (seat) => ({
      PlaySDT,
      ScreenCd,
      ShowSeq,
      SeatGroup: seat?.SeatGroup,
      SeatNo: seat?.SeatNo
    })

    // API 호출 및 상태 업데이트 함수
    const fetchSeatData = async (seatData) => {
      try {
        const {Recordset} = await seat.salesSeatList(createPayload(seatData))
        const [data] = Recordset

        const couponSeatList = await getUsedCouponSeatListApi()

        data.isCouponSeat = !!couponSeatList.some(couponSeat => couponSeat === (data?.SeatGroup + data?.SeatNo))

        setSoldSeat((prev) => [...new Set([...prev, data])])
      } catch (error) {
        console.error('Error SalesSeatList:', error)
      }
    }

    // 각 판매 완료된 좌석에 대해 API 호출
    try {
      await Promise.all(soldSeat.map(fetchSeatData))
    } catch (error) {
      console.error('Error in fetching all seat data:', error)
    }
  }

  // 쿠폰 사용한 좌석 (무료, 유료 구분하기 위함)
  const getUsedCouponSeatListApi = async () => {
    const {movie} = formData
    if (movie.MovieCd) {
      try {
        const date = utils.formatDateTime(movie.PlaySDT, movie.StartTime)
        const {records} = await table.usedCouponSeatCheck(date)

        const result = records.map(item => {
          // item?.fields?.Seat가 존재하고 비어있지 않다면
          if (!item?.fields?.Seat) return []

          return item.fields.Seat.split(',').map(seat => seat.trim())
        })

        return result.flat()
      } catch (err) {
        console.error('Error get usedCouponSeat:', err)
      }
    }
  }

  // 좌석 리스트
  const getDtryxSeatListApi = async () => {
    const {movie} = formData
    if (movie.MovieCd) {
      try {
        const r = await seat.seatList(movie.ScreenCd, movie.PlaySDT, movie.ShowSeq)
        const maxRow = Math.max(...r.Recordset.map(seat => Number(seat.LocationRow)))
        const maxCol = Math.max(...r.Recordset.map(seat => Number(seat.LocationCol)))

        const newSeatMatrix = createSeatMatrix(r.Recordset, maxRow, maxCol)
        if (JSON.stringify(newSeatMatrix) !== JSON.stringify(seatMatrix)) {
          setSeatMatrix(newSeatMatrix)
        }
      } catch (error) {
        console.error('Error fetching seat list:', error)
      }
    }
  }

  const createSeatMatrix = (seatList, maxRow, maxCol) => {
    const matrix = Array.from({length: maxRow}, () => Array(maxCol).fill(null))


    // 좌석 데이터를 배열에 배치
    seatList.forEach(seat => {
      const row = Number(seat.LocationRow) - 1
      const col = Number(seat.LocationCol) - 1
      matrix[row][col] = {...seat}
    })

    return matrix
  }

  const handleInputChange = (event, idx) => {
    const targets = Array.isArray(event.target) ? event.target : [event.target]

    setFormData((prev) => {
      const updatedFormData = {...prev}

      targets.forEach(({name, value, id}) => {
        let updatedValue

        if (name === 'couponCode') {
          let newCouponCode = Array.isArray(prev.couponCode) ? [...prev.couponCode] : []

          if (id !== 'selectPackage') {
            newCouponCode[idx] = {
              id: null,
              code: value.toUpperCase(),
              status: utils.hasKoreanText(value) ? utils.COUPON_CHECK_TYPE.ENG : utils.COUPON_CHECK_TYPE.NONE,
              basicPackage: null
            }
          } else {
            newCouponCode[idx] = {
              ...newCouponCode[idx], basicPackage: value
            }
          }

          updatedValue = newCouponCode
        } else if (name === 'movie') {
          updatedValue = JSON.parse(value)
        } else {
          updatedValue = value
        }

        updatedFormData[name] = updatedValue
      })

      return updatedFormData
    })
  }

  const toggleFullScreen = () => {
    setIsFullScreen(prev => !prev)
  }

  const fullScreenStyle = ({
    position: 'fixed',
    right: 0,
    left: 0,
    bottom: 0,
    top: 0,
    maxWidth: '100vw',
    maxHeight: '100vh',
    background: 'rgb(229,231,235)',
    zIndex: 999
  })

  const resetState = () => {
    setSoldSeat([])
    setSeatFocus(null)
  }

  useEffect(() => {
    resetState()
    setSeatMatrix([])
    getDtryxHistoryListApi()
  }, [formData?.date])

  useEffect(() => {
    resetState()
    setLoading(true)
    getDtryxSeatListApi().finally(() => setLoading(false)) // 좌석
  }, [formData?.movie?.StartTime])

  useEffect(() => {
    resetState()
    setLoading(true)
    getDtryxSalesSeatListApi().finally(() => setLoading(false))
  }, [seatMatrix])


  return (
    <>
      <Helmet>
        <title>MONOPLEX @RYSE Hongdae 좌석별 패키지 판매 현황</title>
        <meta name="description" content="MONOPLEX @RYSE Hongdae 좌석별 패키지 판매 현황"/>
        <meta property="og:title" content="MONOPLEX @RYSE Hongdae 좌석별 패키지 판매 현황"/>
        <meta property="og:description" content="MONOPLEX @RYSE Hongdae 좌석별 패키지 판매 현황"/>
      </Helmet>

      <main className="relative mt-4 bg-gray-200">
        <div className="xl:w-[840px] w-full m-auto h-full rounded pb-[70px]">
          <Header isReservation={false}/>

          <ScheduleTable formData={formData} setFormData={setFormData} list={list} handleInputChange={handleInputChange} setLoading={setLoading}/>

          {formData?.movie?.MovieNm &&
            <div style={isFullScreen ? fullScreenStyle : {}}>
              <SalesPackageSeat seatMatrix={seatMatrix}
                                soldSeat={soldSeat}
                                seatFocus={seatFocus}
                                setSeatFocus={setSeatFocus}
                                loading={loading}
                                isFullScreen={isFullScreen}
                                toggleFullScreen={toggleFullScreen}
                                list={list.filter(({PlaySDT, StartTime}) => formData?.movie?.PlaySDT === PlaySDT && formData?.movie?.StartTime === StartTime)}
              />

              {isFullScreen && <div className={'mb-3'}/>}

              {soldSeat.length !== 0 &&
                <OrderState soldSeat={soldSeat}
                            seatFocus={seatFocus}
                            loading={loading}
                            setSeatFocus={setSeatFocus}
                            isFullScreen={isFullScreen}
                />
              }
            </div>
          }
        </div>

        <Footer/>
      </main>
    </>
  )
}