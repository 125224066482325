import StyledContainer from '../StyledContainer'
import DatePicker from 'react-datepicker'
import '../../css/datePicker.css'
import DatePickerInput from './datePicker/DatePickerInput'
import { DatePickerHeader } from './datePicker/DatePickerHeader'
import { ScheduleListTable } from './ScheduleListTable'
import React, { useRef } from 'react'
import { FaRegCircleQuestion } from 'react-icons/fa6'
import { useLocation } from 'react-router-dom'

export const ScheduleTable = ({formData, setFormData, list, handleInputChange}) => {
  const calendar = useRef(null)
  const {pathname} = useLocation();

  const todayBtn = (e) => {
    e.preventDefault()
    setFormData(prev => ({...prev, date: new Date()}))
    calendar.current.setOpen(false)
  }

  return (
    <StyledContainer>
      <div className={'flex justify-between'}>
        <div className="text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500">
          상영 시간표
        </div>
        {pathname === '/' &&
          <p className={'text-xs text-gray-500 flex items-center gap-1'}><FaRegCircleQuestion/> <span>영화 시작시간 1시간 전까지 예매 가능합니다.</span></p>
        }
      </div>

      <div className={'w-full xs:w-40 items-center gap-2 mt-3'}>
        <DatePicker
          ref={calendar}
          fixedHeight
          locale={'ko'}
          selected={formData?.date}
          name="date"
          onChange={(date) => setFormData(prev => ({...prev, date}))}
          dateFormat="yyyy-MM-dd(eee)"
          minDate={new Date()}
          withPortal
          customInput={<DatePickerInput/>}
          renderCustomHeader={(props) => (<DatePickerHeader {...props}/>)}
        >
          <div className={'flex items-center border-t py-2 justify-center w-full'}>
            <button
              className="text-black border border-gray-200 font-medium px-3 py-2.5 bg-gray-100 rounded hover:bg-gray-200 transition"
              onClick={todayBtn}
            >
              오늘
            </button>
          </div>
        </DatePicker>

      </div>


      {list.length !== 0 ?
        <div className="mt-4 overflow-x-auto rounded-lg border border-gray-200">
          <ScheduleListTable formData={formData} setFormData={setFormData} list={list} handleInputChange={handleInputChange}/>
        </div>
        :
        <div className={'mt-3 text-sm text-gray-700'}>상영 스케줄이 없습니다.</div>
      }
    </StyledContainer>
  )
}