import { format, isBefore, subHours } from 'date-fns'

export const utils = {
  COUPON_CHECK_TYPE: {
    NONE: '', DUPLICATE: '중복된 쿠폰입니다.', FAIL: '등록되지 않은 쿠폰입니다.', SUCCESS: '사용 가능한 쿠폰입니다.', ENG: '영어로 입력해야 합니다.'
  },
  SOLD_TICKETCD: {
    '002451': 'B_PKG-1', // 베이직 패키지 1 : 컵과일
    '002452': 'B_PKG-2', // 베이직 패키지 2 : 디저트 3종
    '002453': 'PKG-1', // 패키지 1 : 치맥세트
    '002454': 'PKG-2-R', // 패키지 2 : 레드와인
    '002459': 'PKG-2-W', // 패키지 2 : 화이트와인
    '002460': 'NORMAL' // 일반
  },
  SEAT_COLOR_TYPE: {
    NORMAL: {color: '#adadad', value: 'normal', label: '1인석', order: 1},
    COUPLE: {color: '#D8565F', value: 'couple', label: '커플석', order: 2},
    SOLDOUT: {color: '#3D3F51', value: 'soldout', label: '예매좌석', order: 3},
    HOLDLOCK: {color: '#986140', value: 'holdlock', label: '보류좌석', order: 4}
  },
  seatColor(seat) {
    if (!seat) return 'transparent'
    const {SeatStatus, SeatType} = seat
    const {NORMAL, COUPLE, SOLDOUT, HOLDLOCK} = this.SEAT_COLOR_TYPE

    const statusColor = {
      [SOLDOUT.value]: SOLDOUT.color,
      [HOLDLOCK.value]: HOLDLOCK.color
    }

    // 우선 SeatStatus로 체크
    if (statusColor[SeatStatus]) {
      return statusColor[SeatStatus]
    }

    const typeColor = {
      [NORMAL.value]: NORMAL.color,
      [COUPLE.value]: COUPLE.color
    }

    // SeatType 체크
    return typeColor[SeatType] || 'transparent'
  },
  packageCell() {
    const cell = [
      {title: 'B_PKG-1', label: '베이직 패키지 1', sub: '컵과일', color: '#72ab1e'},
      {title: 'B_PKG-2', label: '베이직 패키지 2', sub: '디저트 3종', color: '#d8569b'},
      {title: 'PKG-1', label: '패키지 1', sub: '치맥세트', color: '#c77f21'},
      {title: 'PKG-2-R', label: '패키지 2-R', sub: '레드와인 & 샤키테리세트', color: '#750000'},
      {title: 'PKG-2-W', label: '패키지 2-W', sub: '화이트와인 & 샤키테리세트', color: '#65ab8e'},
      {title: '합계', label: 'TOTAL'}
    ]

    cell.forEach(r => {
      r.value = r.title // value 추가

      const ticketCodes = Object.keys(this.SOLD_TICKETCD).filter(
        key => this.SOLD_TICKETCD[key] === r.title
      )
      r.code = ticketCodes[0] || 'TOTAL'
    })

    return cell
  },
  preventScroll() {
    const currentScrollY = window.scrollY
    document.body.style.position = 'fixed'
    document.body.style.width = '100%'
    document.body.style.top = `-${currentScrollY}px` // 현재 스크롤 위치
    document.body.style.overflowY = 'scroll'
    return currentScrollY
  },
  allowScroll() {
    document.body.style.position = ''
    document.body.style.width = ''
    document.body.style.top = ''
    document.body.style.overflowY = ''
  },
  hasKoreanText(text) {
    return /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(text)
  },
  isTimeBefore(row, pathname) {
    const currentDate = new Date()

    if (pathname === '/') {
      // 현재 시간에서 1시간 전으로 설정
      const oneHourBefore = subHours(new Date(`${row.PlaySDT}T${row.StartTime}`), 1)

      // 현재 시간이 영화 시작 1시간 이전보다 전인지 확인
      return isBefore(currentDate, oneHourBefore)
    }

    const formattedDate = format(currentDate, 'yyyy-MM-dd HH:mm')
    return isBefore(formattedDate, `${row.PlaySDT} ${row.StartTime}`)
  },
  hasDuplicateByKey(coupon, key) {
    if (coupon?.length > 1) {
      return coupon?.some((r, idx) => r?.code === coupon[key]?.code && idx !== key)
    }
  },
  formatDateTime(PlaySDT, StartTime) {
    const dateTimeString = `${PlaySDT} ${StartTime}`

    const localDate = new Date(dateTimeString)

    const utcDate = new Date(Date.UTC(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      localDate.getHours(),
      localDate.getMinutes(),
      localDate.getSeconds()
    ))

    return utcDate.toISOString()
  }

}